import { Nullable } from 'types/helpers'

export interface StripeResponse {
  error?: { message?: string }
  paymentIntent?: { status: string }
  confirmationToken?: {
    id: string
  }
}

export interface StripeClient {
  confirmP24Payment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  confirmSofortPayment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  confirmIdealPayment: (clientSecretString: string, paymentMethodData: object) => Promise<StripeResponse>
  confirmPayment: (params: {
    elements?: StripeElement
    clientSecret: string
    confirmParams: {
      return_url: string
      payment_method?: string
      confirmation_token?: string
    }
  }) => Promise<StripeResponse>
  confirmCardPayment: (
    clientSecretString: string,
    paymentMethodData?: object,
    extData?: { handleActions: boolean }
  ) => Promise<StripeResponse>
  createConfirmationToken: (data: {
    elements: StripeElement
    params: {
      payment_method_data: {
        billing_details: {
          name: string
          email: string
          phone: string
          address: {
            city: string
            country: string
            postal_code: string
            state: string
            line1: string
            line2: string
          }
        }
      }
    }
  }) => Promise<StripeResponse>
}

export interface StripeElement {
  show?: () => void
  on?: (event: string, callback: (ev?: { paymentMethod: { id: string } }) => Promise<void> | void) => void
  submit?: () => Promise<{ error: string }>
}

export interface StripePaymentParams {
  clientSecret: Nullable<string>
  redirectionUrl?: Nullable<string>
  payerFullName: Nullable<string>
  payerCountryCode: Nullable<string>
  payerEmail: Nullable<string>
  amount?: number
  redirectLink?: string
}

export interface StripeElementsParams {
  mode: 'payment' | 'setup' | 'subscription'
  paymentMethodTypes: string[]
  currency: string
  amount?: number
  onBehalfOf?: string
  paymentMethodCreation?: 'manual'
  fields?: {
    billingDetails: 'never'
  }
  setupFutureUsage?: 'on_session' | 'off_session'
}

export interface KlarnaFormPayload {
  email: string
  name: string
  phone: string
  address: {
    country: string
  }
}

export interface StripeElementsIndentParams {
  clientSecret: string
}

export interface BillingDetails {
  name: string
  email: string
  phone?: string
  address?: {
    line1?: string
    line2?: string
    city?: string
    state?: string
    country?: string
    postal_code?: string
  }
}
