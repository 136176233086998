import { makeObservable, action } from 'mobx'

import { AffiliateProgramsStore } from 'shared/stores/affiliatePrograms.store'
import { AudioPlayersStore } from 'shared/stores/audioPlayers.store'
import { CookiesConsentCategoriesStore } from 'shared/stores/cookiesConsentCategories.store'
import { I18nStore } from 'shared/stores/i18n.store'
import type { ApiClient } from 'utils/requests.utils'
import type { ApiClientV2 } from 'utils/api-client/api-client.utils'

import { CurrenciesStore } from 'shop/stores/currencies.store'
import { CountriesStore } from 'shop/stores/countries.store'
import { AddonThemesStore } from 'shop/stores/orderBumpsThemes.store'
import { AffiliateLandingsStore } from 'shop/stores/affiliateLandings.store'
import { AffiliatesStore } from 'shop/stores/affiliates.store'
import { CancellationTermsStore } from 'shop/stores/cancellationTerms.store'
import { ContentPageStore } from 'shop/stores/contentPage.store'
import { CourseViewsStore } from 'shop/stores/courseViews.store'
import { EmbeddableItemsStore } from 'shop/stores/embeddableItems.store'
import { FunnelsStore } from 'shop/stores/funnels.store'
import { LessonsStore } from 'shop/stores/lessons.store'
import { MobileAppDocumentsStore } from 'shop/stores/mobileAppDocuments.store'
import { OrdersStore } from 'shop/stores/orders.store'
import { PaymentStore } from 'shop/stores/payment.store'
import { ProductGroupsStore } from 'shop/stores/productGroups.store'
import { ProductSettingsStore } from 'shop/stores/productSettings.store'
import { ProductsStore } from 'shop/stores/products.store'
import { ProductUpsellsStore } from 'shop/stores/productUpsells.store'
import { SellablesStore } from 'shop/stores/sellables.store'
import { FontsStore } from 'shop/stores/sellerFonts.store'
import { SellersStore } from 'shop/stores/sellers.store'
import { SellerStore } from 'shop/stores/seller.store'
import { SharingsStore } from 'shop/stores/sharings.store'
import { ThemeStore } from 'shop/stores/theme.store'
import { TicketDatesStore } from 'shop/stores/ticketDates.store'
import { TicketStore } from 'shop/stores/tickets.store'
import { SetupIntents } from 'shop/stores/setupIntents.store'
import { CourseThemesStore } from 'shop/stores/courseThemes.store'
import { DealsStore } from 'shop/stores/deals.store'
import { PurchaseMethodsStore } from 'shop/stores/purchaseMethods.store'
import { ShopThemeStore } from 'shop/stores/shopTheme.store'
import { PaymentCountriesStore } from 'shop/stores/paymentCountries.store'
import { DownloadGoodsStore } from 'shop/stores/downloadGoods.store'
import { CookiesConsentsStore } from 'shop/stores/cookiesConsents.store'
import { UserStore } from 'shop/stores/user.store'
import { TrackingCodesStore } from 'shop/stores/trackingCodes.store'
import { TrackingEventsStore } from 'shop/stores/trackingEvents.store'
import { TracifyStore } from 'shop/stores/tracify.store'
import { TrackingLogStore } from 'shop/stores/trackingLog.store'
import { TrackingUserEventsStore } from 'shop/stores/trackingUserEvents.store'
import { ExperimentsStore } from 'shop/stores/experiments.store'
import { TicketsAvailabilityStore } from 'shop/stores/ticketsAvailability.store'
import { UpgradeTicketsStore } from 'shop/stores/upgradeTickets.store'

export class ShopRootStore {
  apiClient: ApiClient
  apiClientV2: ApiClientV2

  addonThemesStore: AddonThemesStore
  affiliateLandingsStore: AffiliateLandingsStore
  ticketsAvailabilityStore: TicketsAvailabilityStore
  affiliateProgramsStore: AffiliateProgramsStore
  affiliatesStore: AffiliatesStore
  audioPlayersStore: AudioPlayersStore
  cancellationTermsStore: CancellationTermsStore
  // TODO: remove empty/useless stores from here and their usage
  communitiesStore: any
  communityMemberStore: any
  contentPageStore: ContentPageStore
  cookiesConsentCategoriesStore: CookiesConsentCategoriesStore
  cookiesConsentsStore: CookiesConsentsStore
  countriesStore: CountriesStore
  courseSessionsStore: any
  courseThemesStore: CourseThemesStore
  courseViewsStore: CourseViewsStore
  currenciesStore: CurrenciesStore
  embeddableItemsStore: EmbeddableItemsStore
  funnelsStore: FunnelsStore
  i18nStore: I18nStore
  lessonsStore: LessonsStore
  membershipThemesStore: any
  mobileAppDocumentsStore: MobileAppDocumentsStore
  ordersStore: OrdersStore
  pageBuilderStore: any
  payerStore: any
  paymentStore: PaymentStore
  productGroupsStore: ProductGroupsStore
  productSessionStore: any
  productSettingsStore: ProductSettingsStore
  productsStore: ProductsStore
  productUpsellsStore: ProductUpsellsStore
  sellablesStore: SellablesStore
  sellerFontsStore: FontsStore
  sellersStore: SellersStore
  sellerStore: SellerStore
  setupIntentsStore: SetupIntents
  sharingsStore: SharingsStore
  themeStore: ThemeStore
  ticketDatesStore: TicketDatesStore
  ticketsStore: TicketStore
  ticketStore: TicketStore
  userStore: UserStore
  trackingUserEventsStore: TrackingUserEventsStore
  dealsStore: DealsStore
  purchaseMethodsStore: PurchaseMethodsStore
  shopThemeStore: ShopThemeStore
  paymentCountriesStore: PaymentCountriesStore
  downloadGoodsStore: DownloadGoodsStore
  trackingCodesStore: TrackingCodesStore
  trackingEventsStore: TrackingEventsStore
  tracifyStore: TracifyStore
  trackingLogStore: TrackingLogStore
  experimentsStore: ExperimentsStore
  upgradeTicketsStore: UpgradeTicketsStore
  commentsStore: any
  quizzesStore: any

  constructor(apiClient: ApiClient, apiClientV2: ApiClientV2) {
    this.apiClient = apiClient
    this.apiClientV2 = apiClientV2

    this.addonThemesStore = new AddonThemesStore(this)
    this.affiliateLandingsStore = new AffiliateLandingsStore(this)
    this.ticketsAvailabilityStore = new TicketsAvailabilityStore(this)
    this.affiliateProgramsStore = new AffiliateProgramsStore(this)
    this.affiliatesStore = new AffiliatesStore(this)
    this.audioPlayersStore = new AudioPlayersStore()
    this.cancellationTermsStore = new CancellationTermsStore(this)
    this.communitiesStore = {}
    this.communityMemberStore = {}
    this.contentPageStore = new ContentPageStore(this)
    this.cookiesConsentCategoriesStore = new CookiesConsentCategoriesStore(this)
    this.cookiesConsentsStore = new CookiesConsentsStore(this)
    this.countriesStore = new CountriesStore(this)
    this.courseSessionsStore = { setExpands: () => {} }
    this.courseThemesStore = new CourseThemesStore(this)
    this.courseViewsStore = new CourseViewsStore(this)
    this.currenciesStore = new CurrenciesStore(this)
    this.embeddableItemsStore = new EmbeddableItemsStore(this)
    this.funnelsStore = new FunnelsStore(this)
    this.i18nStore = new I18nStore()
    this.lessonsStore = new LessonsStore(this)
    this.membershipThemesStore = {}
    this.mobileAppDocumentsStore = new MobileAppDocumentsStore(this)
    this.ordersStore = new OrdersStore(this)
    this.pageBuilderStore = {}
    this.payerStore = {}
    this.paymentStore = new PaymentStore(this)
    this.productGroupsStore = new ProductGroupsStore(this)
    this.productSessionStore = {}
    this.productSettingsStore = new ProductSettingsStore(this)
    this.productsStore = new ProductsStore(this)
    this.productUpsellsStore = new ProductUpsellsStore(this)
    this.sellablesStore = new SellablesStore(this)
    this.sellerFontsStore = new FontsStore(this)
    this.sellersStore = new SellersStore(this)
    this.sellerStore = new SellerStore(this)
    this.setupIntentsStore = new SetupIntents(this)
    this.sharingsStore = new SharingsStore(this)
    this.themeStore = new ThemeStore(this)
    this.ticketDatesStore = new TicketDatesStore()
    this.ticketsStore = new TicketStore(this)
    this.ticketStore = new TicketStore(this)
    this.userStore = new UserStore(this)
    this.trackingUserEventsStore = new TrackingUserEventsStore(this)
    this.dealsStore = new DealsStore(this)
    this.purchaseMethodsStore = new PurchaseMethodsStore(this)
    this.shopThemeStore = new ShopThemeStore(this)
    this.paymentCountriesStore = new PaymentCountriesStore(this)
    this.downloadGoodsStore = new DownloadGoodsStore(this)
    this.trackingCodesStore = new TrackingCodesStore(this)
    this.trackingEventsStore = new TrackingEventsStore(this)
    this.tracifyStore = new TracifyStore(this)
    this.trackingLogStore = new TrackingLogStore(this)
    this.upgradeTicketsStore = new UpgradeTicketsStore(this)
    this.experimentsStore = new ExperimentsStore()
    this.commentsStore = {}
    this.quizzesStore = {}

    makeObservable(this)
  }

  @action hydrate(initialData) {
    if (initialData) {
      Object.keys(initialData).map((storeName) => {
        if (this[storeName]) {
          Object.keys(initialData[storeName]).map((key) => {
            this[storeName].hydrate(key, initialData[storeName][key])
          })
        }
      })
    }
  }
}
