type Nullable<T> = T | null
type PayMethod = 'paypal' | 'card' | 'sofort' | 'bank_wire' | 'sepa' | 'pay_later' | 'p_24' | 'apple_pay' | 'google_pay'
type PricingPlanForm = 'one_time' | 'subscription' | 'limited_subscription' | 'installment'
type ProfileTypesWithIds = {
  seller?: number
  payer?: number
  manager?: number
  publisher?: number
  teamMember?: number
  eloPublisher?: number
}

type AnyCallback = (...args: any[]) => any

type SnakeCase<T extends string> = T extends `${infer Head}${infer Rest}`
  ? `${Head extends Capitalize<Head> ? '_' : ''}${Lowercase<Head>}${SnakeCase<Rest>}`
  : T

type SnakeCaseKeys<T> = T extends object
  ? {
      [K in keyof T as SnakeCase<string & K>]: SnakeCaseKeys<T[K]>
    }
  : T

type CamelCase<T extends string> = T extends `${infer Before}_${infer Char}${infer Rest}`
  ? `${Before}${Uppercase<Char>}${CamelCase<Rest>}`
  : T

type CamelCaseKeys<T> = T extends any[]
  ? { [K in keyof T]: CamelCaseKeys<T[K]> }
  : T extends object
    ? {
        [K in keyof T as CamelCase<string & K>]: CamelCaseKeys<T[K]>
      }
    : T

type FromSwagger<T, K = string> = CamelCaseKeys<T> & { id: K }

type SelectOption<T> = CamelCaseKeys<T> & {
  label: string
  value: string
}

export type {
  PayMethod,
  PricingPlanForm,
  Nullable,
  AnyCallback,
  ProfileTypesWithIds,
  SnakeCaseKeys,
  CamelCaseKeys,
  FromSwagger,
  SelectOption,
}
