import { isWindowEnv } from 'utils/env.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

const defaultHost = `https://${getElopageConfig('webHost')}`

export const scriptHostRegexp = new RegExp('https?://[^/]*')
export const script = isWindowEnv()
  ? document.getElementById('elopage-embedable-form-script') ||
    document.getElementById('elopage-checkout-trigger-script') ||
    document.getElementById('elopage-widgets-script') ||
    document.getElementById('elopage-embedable-script-snippet') ||
    document.getElementById('elopage-funnel-script')
  : null
export const host = (() => {
  if (script) {
    return script.getAttribute('src').match(scriptHostRegexp)[0]
  }
  return defaultHost
})()

export const eloFormContainer = isWindowEnv() ? document.getElementById('elopage-embedable-form-wrapper') : null

export const ELOPAGE_SCRIPT_STATES = {
  shimInited: 'shimInited',
  iframeInited: 'iframeInited',
  iframeLoaded: 'iframeLoaded',
}

export const IFRAME_LOAD_STATES = {
  loading: 'loading',
  handshakeCompleted: 'handshakeCompleted',
}

export const POST_MESSAGE_ACTIONS = {
  iframeInjected: 'iframeInjected',
  iframeMounted: 'iframeMounted',
  redirection: 'redirection',
}
