import { COLORS } from '@elo-kit/constants/general.constants'
import { HEADER } from '@elo-kit/constants/contentPage.constants'
import { SUB_MENU_CONTAINER_Z_INDEX, HEADER_CONTAINER_Z_INDEX } from '@elo-kit/constants/block.constants'
import { getFontStyles } from '@elo-kit/utils/fonts.utils'
import { cleanupFonts } from 'utils/fonts.utils'

const { eloBlue, eloGrey40, eloWhite } = COLORS

const headerStyles = (theme) => ({
  headerContainer: {
    background: theme[HEADER.background] || eloWhite,
    width: '100%',
  },
  headerContainerPreview: {
    border: 'dashed 1px #00000021',
  },
  headerContainerSticky: {
    position: 'sticky',
    top: 0,
    zIndex: HEADER_CONTAINER_Z_INDEX,
  },
  hideHeader: {
    display: 'none',
  },
  header: {
    display: 'flex',
    maxWidth: '1140px',
    height: '91px',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme[HEADER.background] || eloWhite,
    '& .burgerMenu': {
      display: 'none',
    },
  },
  headerPreview: {
    position: 'relative',
    display: 'flex',
    maxWidth: '1140px',
    height: '91px',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme[HEADER.background] || eloWhite,
    '& .burgerMenu': {
      display: 'none',
      '& .fa-times': {
        fontSize: '20px',
        width: '25px',
        color: eloGrey40,
      },
    },
    '@media (max-width: 991px)': {
      height: '61px',
    },
  },
  headerMobileRight: {
    display: 'none',
  },
  logo: {
    display: 'flex',
    marginLeft: '15px',
    '& img': {
      width: 'auto',
      maxHeight: '50px',
      '@media (max-width: 991px)': {
        maxHeight: '40px',
      },
    },
    '&:hover': {
      color: eloGrey40,
      textDecoration: 'none',
    },
  },
  logoPreview: {
    marginLeft: '15px',
    width: '150px',
    fontFamily: 'Montserrat Medium',
    fontSize: '15px',
    color: eloGrey40,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'dashed 1px #00000021',
    '&:hover': {
      color: eloGrey40,
      textDecoration: 'none',
    },
    borderRadius: '4px',
    height: '50px',
  },
  logoUsername: {
    marginLeft: '15px',
    fontFamily: 'Montserrat Medium',
    color: '#4a4a4a',
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& img': {
      width: '40px',
      height: '40px',
      marginRight: '10px',
      borderRadius: '50%',
    },
    '& span': {
      fontSize: '15px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  headerRight: {
    display: 'flex',
    alignItems: 'baseline',
    '& > div': {
      margin: '0 10px',
      cursor: 'pointer',
    },
  },
  menu: {
    display: 'flex',
    marginRight: '20px',
    alignItems: 'baseline',
    '& > div, & > a': {
      margin: '0 10px',
    },
    '& > a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  socialsContainer: {
    display: 'flex',
  },
  myAccount: {
    fontSize: theme[HEADER.accountSize] ? `${theme[HEADER.accountSize]}px` : '15px',
    fontFamily: cleanupFonts(theme[HEADER.accountFont]) || 'Montserrat Reg',
    ...getFontStyles(theme[HEADER.accountStyle]),
    color: theme[HEADER.accountColor] || eloBlue,
    display: 'flex',
    alignItems: 'baseline',
    lineHeight: '1.2',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      opacity: 0.8,
      color: theme[HEADER.accountColor] || eloBlue,

      '& .fa-sort-down': {
        transform: 'rotate(180deg)',
        marginTop: 6,
        marginBottom: 0,
      },
    },
    '&:hover .submenus': {
      display: 'block',
    },
    '& .fa-sort-down': {
      alignSelf: 'center',
      marginBottom: 6,
      marginLeft: 2,
    },
    '@media (max-width: 576px)': {
      fontSize: theme[HEADER.accountSize] > 20 ? 20 : theme[HEADER.accountSize],
    },
  },
  myAccountIcon: {
    color: theme[HEADER.iconColor] || eloBlue,
    marginRight: '5px',
  },
  myAccountContainer: {
    position: 'absolute',
    minWidth: 'max-content',
    borderRadius: '6px',
    background: theme[HEADER.background] || eloWhite,
    display: 'none',
    top: '100%',
    '& a': {
      display: 'block',
      margin: '15px 20px',
      color: theme[HEADER.accountColor] || eloBlue,
      fontSize: theme[HEADER.accountSize] ? `${theme[HEADER.accountSize]}px` : '15px',
      fontFamily: cleanupFonts(theme[HEADER.accountFont]) || 'Montserrat Reg',
      ...getFontStyles(theme[HEADER.accountStyle]),
      textDecoration: 'none',
      ...getFontStyles(theme[HEADER.menuStyle]),
      '&:hover': {
        opacity: 0.7,
        color: theme[HEADER.accountColor] || eloBlue,
      },
    },
  },
  myAccountMobileActive: {},
  menuMobileActive: {},
  headerMobileMenu: {},
  headerMobileMenuActive: {},
  socialsContainerMobileActive: {},
  '@media (max-width: 991px)': {
    header: {
      position: 'fixed',
      top: '0',
      width: '100%',
      height: '60px',
      padding: '0',
      zIndex: '2',
      '& .burgerMenu': {
        display: 'flex',
        margin: '0 15px 0 30px',
      },
    },
    headerPreview: {
      '& .burgerMenu': {
        display: 'flex',
        margin: '0 15px 0 30px',
      },
    },
    headerMobileRight: {
      display: 'flex',
      alignItems: 'baseline',
      '& > div': {
        margin: '0 10px',
        cursor: 'pointer',
      },
      '& .fa-times': {
        fontSize: '20px',
        width: '25px',
        color: theme[HEADER.menuColor] || eloGrey40,
        '&:hover': {
          opacity: 0.8,
        },
      },
    },
    socialsContainer: {
      display: 'none',
    },
    headerMobileMenu: {
      display: 'none',
    },
    headerMobileMenuActive: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '60px',
      width: '100%',
      background: theme[HEADER.background] || eloWhite,
      fontSize: '20px',
      padding: '15px',
      boxShadow: '0px 13px 13px 0px #0000004d',
      zIndex: 4,
    },
    menuMobileActive: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '0 10px',
      '& > a': {
        margin: '15px 0px',
      },
      '& > div': {
        margin: '15px 0px',
        '& > div': {
          position: 'initial',
        },
        '& a': {
          margin: '30px 20px',
          '&:last-child': {
            marginBottom: '10px',
          },
        },
      },
      '& .mobile-my-account': {
        margin: '15px 0px',
        flexDirection: 'column',
        '& .fa-sort-down': {
          marginLeft: '20px',
          marginTop: '-15px',
          alignSelf: 'start',
        },
        '& a': {
          margin: '10px 20px',
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
    myAccount: {
      '&:hover': {
        '& .fa-sort-down': {
          transform: ['rotate(180deg)', 'translateY(-40%)'],
        },
      },
    },
    myAccountMobileActive: {
      display: ['none', '!important'],
    },
    socialsContainerMobileActive: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      padding: '15px 0 30px 5px',
      '& a': {
        marginRight: '20px',
        marginBottom: '5px',
      },
    },
  },
  profileInfo: {
    display: 'flex',
    alignItems: 'baseline',
    zIndex: 5,
    '& i': { marginRight: '7px' },
    '& a': { textDecoration: 'none' },
  },
  burgerMenu: {
    margin: '0 10px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  menuItem: {
    fontSize: theme[HEADER.menuSize] ? `${theme[HEADER.menuSize]}px` : '15px',
    fontFamily: cleanupFonts(theme[HEADER.menuFont]) || 'Montserrat Medium',
    color: `${theme[HEADER.menuColor] || eloBlue} !important`,
    textDecoration: 'none',
    border: 'none',
    background: 'none',
    padding: 0,
    alignSelf: 'flex-start',
    ...getFontStyles(theme[HEADER.menuStyle]),
    '&:hover': {
      opacity: 0.8,
      color: theme[HEADER.menuColor] || eloBlue,
    },

    '@media (max-width: 576px)': {
      fontSize: theme[HEADER.menuSize] > 26 ? 26 : theme[HEADER.menuSize],
    },
  },
  menuWithSubmenu: {
    '& .submenus': {
      display: 'none',
    },
    '&:hover .submenus': {
      display: 'block',
    },
    '&:active .submenus': {
      display: 'block',
    },
    position: 'relative',
    alignSelf: 'flex-start',
  },
  submenuContainer: {
    position: 'absolute',
    minWidth: '150px',
    borderRadius: '6px',
    zIndex: SUB_MENU_CONTAINER_Z_INDEX,
    background: theme[HEADER.background] || eloWhite,
  },
  submenuItem: {
    display: 'block',
    margin: '15px 20px',
    color: theme[HEADER.menuColor] || eloBlue,
    fontSize: theme[HEADER.menuSize] ? `${theme[HEADER.menuSize]}px` : '15px',
    fontFamily: cleanupFonts(theme[HEADER.menuFont]) || 'Montserrat Medium',
    textDecoration: 'none',
    ...getFontStyles(theme[HEADER.menuStyle]),
    '&:hover': {
      opacity: 0.8,
      color: theme[HEADER.menuColor] || eloBlue,
    },
  },
  socials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    background: theme[HEADER.socialsColor] || eloBlue,
    border: theme[HEADER.socialsColor] ? 'none' : 'dashed 1px #00000021',
    borderRadius: '50%',
    color: eloWhite,
    margin: '0 5px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8,
      color: eloWhite,
      cursor: 'pointer',
    },
  },
})

export default headerStyles
