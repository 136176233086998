import { action, computed, observable, runInAction, makeObservable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { PRODUCT_TYPE_IDS } from 'constants/productsShared.constants'
import { getDefaultProductsList } from 'constants/contentPageShared.constants'
import { DEFAULT_FULL_LIST, DEFAULT_PAGE, DEFAULT_SHOP_ITEMS_PER_PAGE } from 'constants/pagination.constants'
import { ELOPAGE_CABINETS } from '@elo-kit/constants/general.constants'
import { ALLOWED_LANGUAGES } from 'constants/general.constants'
import { getObjectWithoutProperties } from 'utils/helpersShared.utils'
import { getNestedChildren } from 'utils/product.utils'

import { Product } from 'shop/api/products.api'

interface CreateSellerLeadsParams {
  contactFormId?: number
  shopThemeId?: number
}

export class ContentPageStore {
  storeName = 'ContentPageStore'
  root: ShopRootStore
  constructor(root: ShopRootStore) {
    this.root = root
    makeObservable(this)
  }

  @observable sellerProductsLoading = true
  @observable productLoading = true

  @observable membershipProducts = getDefaultProductsList()
  @observable sellerProducts = {}
  @observable popularProducts = {} as Record<string, any>
  @observable product: Product = {}
  @observable lessons = []
  @observable loading = false
  @observable embeddableItem = {}
  @observable view = ELOPAGE_CABINETS.shop

  @observable funnelHandled = false
  @observable affiliateHandled = false

  @observable productProtectedByPass: string | boolean = false
  @observable sortedCategories = null
  @observable activeTab = null

  @observable scopes = new Map()
  @observable sellerProductsQuery = ''

  @computed get affiliateProgramsStore() {
    return this.root.affiliateProgramsStore
  }

  @computed get ordersStore() {
    return this.root.ordersStore
  }

  @computed get seller() {
    return this.root.sellerStore.item
  }

  @computed get courseThemesStore() {
    return this.root.courseThemesStore
  }

  @computed get lessonsStore() {
    return this.root.lessonsStore
  }

  @computed get currenciesStore() {
    return this.root.currenciesStore
  }

  @computed get downloadGoodsStore() {
    return this.root.downloadGoodsStore
  }

  @observable pagination = {
    page: DEFAULT_PAGE,
    per: DEFAULT_SHOP_ITEMS_PER_PAGE,
    total: 0,
  }

  @action setActiveTab = (value) => (this.activeTab = value)
  @action setSortedCategories = (value) => (this.sortedCategories = value)
  @action setFunnelHandled = (value: boolean) => (this.funnelHandled = value)
  @action setAffiliateHandled = (value: boolean) => (this.affiliateHandled = value)
  @action protectProductByPass = (pass: string) => (this.productProtectedByPass = pass)
  @action toggleLoading = (loading: boolean) => (this.loading = loading)

  setProductsCustomLoading = () => {}

  @action setSellerProducts = (blockId: number, data = {}) => {
    this.sellerProducts = {
      ...this.sellerProducts,
      [blockId]: {
        ...(this.sellerProducts[blockId] || {}),
        ...data,
      },
    }
  }

  @action resetQueryParams = () => {
    this.scopes = new Map()
    this.pagination = {
      page: DEFAULT_PAGE,
      per: DEFAULT_SHOP_ITEMS_PER_PAGE,
      total: 0,
    }
  }

  @action setPagination = (pagination = {}, blockId: number) => {
    this.sellerProducts[blockId] = {
      ...(this.sellerProducts[blockId] || {}),
      pagination: {
        ...(this.sellerProducts[blockId]?.pagination || {}),
        ...pagination,
      },
    }
  }

  @action handlePaginationChange = (key: string, value: number, params: { name: string }, blockId: number) => {
    this.setPagination({ [key]: value }, blockId)
  }

  @action handleScopeChange = (key: string, value: string) => {
    this.scopes.set(key, value)
  }

  @computed get themePages() {
    return this.root.shopThemeStore?.shopPages
  }

  @computed get availableLanguages() {
    if (!this.root.shopThemeStore.shopTheme.prefs.languages?.length) {
      return Object.values(ALLOWED_LANGUAGES)
    }

    return this.root.shopThemeStore.shopTheme.prefs.languages
  }

  isAppActive = (key: string) => this.root.sellerStore.isAppActive(key)

  fetchProduct = async (slug: string, history: any, isPreview: boolean, username?: string, withoutLimit?: boolean) => {
    if (this.root.productsStore.product.slug === slug) {
      return this.root.productsStore.product
    }
    // TODO: ts - remove history from store
    runInAction(() => (this.productLoading = true))

    const resp = await this.root.productsStore.fetchProduct(slug, history, isPreview, username, withoutLimit)
    runInAction(() => (this.product = resp.data))
    const { accessPassword, form, id } = this.product || {}

    this.protectProductByPass(accessPassword)

    if (form === PRODUCT_TYPE_IDS.course) {
      const respL = await this.root.lessonsStore.fetchFullList({
        username: username || this.root.sellerStore.item.username,
        productId: id,
        active: true,
      })
      const { list = [] } = respL.data || {}
      runInAction(() => (this.lessons = getNestedChildren(list, null)))
    }

    runInAction(() => (this.productLoading = false))

    return resp
  }

  fetchProducts = async (data: { name: string }, blockId: number, username: string) => {
    this.setSellerProducts(blockId, { loading: true })
    this.sellerProductsLoading = true
    runInAction(() => {
      this.sellerProductsQuery = data.name || ''
    })
    const resp = await this.root.productsStore.fetchProducts(username || this.root.sellerStore.item.username, {
      ...this.pagination,
      ...getObjectWithoutProperties(this.sellerProducts[blockId]?.pagination, ['total']),
      ...data,
    })

    const { data: list = [], totalCount = 0, included } = resp || {}

    this.setSellerProducts(blockId, {
      list,
      included,
      pagination: {
        page: DEFAULT_PAGE,
        per: DEFAULT_SHOP_ITEMS_PER_PAGE,
        ...(this.sellerProducts[blockId]?.pagination || {}),
        total: totalCount,
      },
      loading: false,
      query: data.name || '',
    })

    return resp
  }

  fetchPopularProducts = async (data: { id: string[] }, username?: string) => {
    // @ts-ignore
    const resp = await this.root.productsStore.fetchProducts(username || this.root.sellerStore.item.username, {
      ...data,
      per: DEFAULT_FULL_LIST,
      productGroupId: null,
    })

    runInAction(() => {
      const { data: list = [], included } = resp || {}
      this.popularProducts[data.id?.join('-')] = list
      if (included?.length) {
        this.popularProducts.content = included
      }
    })
    return resp
  }

  handleFunnelAccept = (data: {
    productId: number
    ticketId: number
    ticketDateId: number
    ticketsCount: number
    pricingPlanId: number
  }) => this.root.ordersStore.onSubmitPostsell(data)
  handleFunnelReject = () => this.root.ordersStore.onRejectPostsell()

  getProductLink = () => '#'
  fetchMembershipProducts = () => {}

  createSellerLeads = (sellerUsername: string, data: CreateSellerLeadsParams) =>
    this.root.themeStore.childApi.createSellerLeads(sellerUsername, data)

  convertToPrice = (value: string | number, currencyId: number | string, separator = '') =>
    this.root.currenciesStore.convertToPrice(value, currencyId, separator)

  @computed get countriesList() {
    return this.root.countriesStore.list
  }

  @computed get user() {
    return this.root.userStore.item
  }

  @action hydrate(key, data) {
    switch (key) {
      case 'product':
        this.product = data
        this.productLoading = false
        return
      case 'lessons':
        this.lessons = data
        this.productLoading = false
        return
      case 'sellerProducts':
        this.sellerProducts = data
        this.pagination.total = data.totalCount
        this.sellerProductsLoading = false
        return
      case 'popularProducts':
        this.popularProducts = data
        return
      case 'pagination':
        this.pagination.page = data.page
        this.pagination.per = data.per
        this.pagination.total = data.total
        return
      case 'sortedCategories':
        this.sortedCategories = data
        return
      case 'activeTab':
        this.activeTab = data
        return
      case 'productProtectedByPass':
        this.productProtectedByPass = data
    }
  }
}
