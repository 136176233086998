import axios from 'axios'

import { isWindowEnv } from 'utils/env.utils'
/* eslint-disable no-restricted-globals */
// TODO: use AbortController https://axios-http.com/docs/cancellation
let abortController = new AbortController()
let prevPathname = isWindowEnv() ? location.pathname : ''
let prevParams = isWindowEnv() ? location.search : ''

export const CANCEL_TOKEN = 'CANCEL_TOKEN'
export const CANCELED_REQUEST_REASON = 'Canceled request due to route change'

/**
 * List to skip cancellation interceptor
 * List item is a function returning Boolean to indicate that request shouldn't be attached with CancelToken
 */
const UNCANCELABLE_REQ_LIST = [
  (req) => req.url.includes('/token'),
  (req) => req.url.includes('/embeddable_items'),
  (req) => req.url.includes('common/seller'),
  (req) => req.url.includes('common/user'),
  (req) => req.url.includes('common/publisher'),
  (req) => req.url.includes('common/elo_publisher'),
  (req) => req.url.includes('common/payer'),
  (req) => req.url.includes('common/team_member'),
  (req) => req.url.includes('common/currencies'),
  (req) => req.url.includes('seller_fonts'),
  (req) => req.url.includes('payer/course_sessions/'),
  (req) => req.url.includes('payer/sellers'),
  (req) => req.url.includes('/course_themes/'),
  (req) => req.url.includes('amazonaws.com'),
  (req) => req.url.includes('pdf.worker'),
  (req) => req.url.includes('stripe.com'),
  (req) => req.url.includes('/currencies'),
  (req) => req.url.includes('/discussions'),
]

/**
 * Check if request can be cancelled
 *
 * @param req {Object} request details
 * @returns {boolean}
 */
export const isRequestCancellable = (req) => !UNCANCELABLE_REQ_LIST.some((fn) => fn(req))

/**
 * Check if request is cancelled
 *
 * @param req {Object}
 * @returns {*|boolean}
 */
export const isReqCanceled = (req) =>
  req[CANCEL_TOKEN] && req[CANCEL_TOKEN].reason && axios.isCancel(req[CANCEL_TOKEN].reason)

/**
 * Cancel requests if location changes
 *
 * @param pathname {string}
 * @param search {string}
 */
export const cancelPendingRequests = ({ pathname, search }) => {
  if (prevPathname !== pathname || pathname === undefined || (prevParams !== search && search)) {
    abortController.abort(CANCELED_REQUEST_REASON)
    abortController = new AbortController()
  }

  /* Configure new prev location */
  prevPathname = pathname
  prevParams = search
}

/**
 * Intercept request outgoing requests for cancelation
 *
 * @param axiosInstance {object}
 */
export const interceptRequestForCancellation = (axiosInstance = axios) => {
  axiosInstance.interceptors.request.use((req) =>
    isRequestCancellable(req) && !isReqCanceled(req)
      ? {
          ...req,
          signal: abortController.signal,
        }
      : req
  )
}
