import { WebVitalsReport } from 'shop/utils/web-vitals-reporter.utils'
import { ApiClient } from 'utils/requests.utils'

const BASE_URL = `/app/kibana`

export const createKibanaApi = ({ POST }: ApiClient) => ({
  createOrder: ({
    sellerId,
    orderPaymentForm,
    orderSessionId,
    orderStatus = 'init',
  }: {
    sellerId: number
    orderSessionId: string
    orderPaymentForm: string
    orderStatus?: string
  }) =>
    POST(
      BASE_URL,
      {
        sellerId,
        orderPaymentForm,
        orderSessionId,
        orderStatus,
        __unsafeOptions: { asJson: true },
      },
      {
        skipErrorNotific: true,
      }
    ),

  buildTriggered: (sellerId: number, isPreview: boolean) =>
    POST(
      BASE_URL,
      {
        sellerId,
        isPreview,
        buildTriggered: true,
        __unsafeOptions: { asJson: true },
      },
      {
        skipErrorNotific: true,
      }
    ),
  reportWebVitals: (body: WebVitalsReport) =>
    POST(
      BASE_URL,
      {
        ...body,
        __unsafeOptions: { asJson: true },
      },
      {
        skipErrorNotific: true,
      }
    ),

  reportAddressValidation: (
    city: string,
    zip: string,
    country: string,
    body?: {
      confidence?: number
      confidenceCityLevel?: number
      matchType?: string
      notValidated?: boolean
    },
    error?: {
      statusCode: number
      error: string
      message?: string
    }
  ) =>
    POST(
      BASE_URL,
      {
        city,
        zip,
        country,
        body,
        error,
        type: 'address_validation',
        __unsafeOptions: { asJson: true },
      },
      {
        skipErrorNotific: true,
      }
    ),

  reportCookieConsent: (
    sellerId: number,
    eventType: 'banner_visibility' | 'open_change_settings' | 'accept_custom' | 'accept_all',
    data: {
      visible?: boolean
      source?: string
      selected?: string[]
      selectedCategories?: string[]
    }
  ) =>
    POST(
      BASE_URL,
      {
        sellerId,
        eventType,
        data,
        type: 'cookie_consent',
        __unsafeOptions: { asJson: true },
      },
      {
        skipErrorNotific: true,
      }
    ),
})

export type KibanaApi = ReturnType<typeof createKibanaApi>
